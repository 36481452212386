import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import colors from 'src/assets/styles/colors'
import distances from 'src/assets/styles/distances'
import typographySizes from 'src/assets/styles/typographySizes'
import Img from 'gatsby-image'
import mediaQuery from 'src/assets/styles/mediaQuery'
import { InView } from 'react-intersection-observer'
import Seo from 'src/components/global/Seo.js'
import { Link } from 'gatsby'

import MainWrapper from 'src/components/global/MainWrapper.js'
import Lines from 'src/components/global/lines/Lines'
import TextBarAnimation from 'src/components/global/TextBarAnimation.js'
import TitleH1 from 'src/components/global/texts/TitleH1.js'
import TitleH2 from 'src/components/global/texts/TitleH2.js'
import arrow from 'src/assets/svg/offer-arrow.svg'
import BarText from 'src/components/global/texts/BarText.js'
import TextAnimationOnStart from 'src/components/global/TextAnimationOnStart.js'
import UnderlineLink from 'src/components/global/buttons/UnderlineLink.js'
const ImageWrapper = styled.div`
  padding: 30rem 0;
  clip-path: polygon(0% 0%, 100% 0%, 100% 0%, 0% 0%);
  transition: all 1s cubic-bezier(0.65, 0, 0.35, 1);

  &.show {
    clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
    transition: all 1.5s 0.2s cubic-bezier(0.65, 0, 0.35, 1);
  }
`
const StyledMain = styled.main`
  background-color: ${colors.black};
  width: 100vw;

  overflow-y: hidden;
  ${MainWrapper} {
    display: flex;
    justify-content: space-between;
    overflow: hidden;
  }
`
const SideCol = styled.div`
  width: 33.33%;
  position: absolute;
  overflow: hidden;
  right: 0;
  top: 0;
  @media (max-width: ${mediaQuery.tablet}) {
    display: none;
  }
`

const MainCol = styled.div`
  width: calc(66.66% - 40rem);

  margin-left: 20rem;
  margin-top: ${distances.mFromTop}rem;
  padding-bottom: ${distances.mBottomPage}rem;

  @media (max-width: ${mediaQuery.tablet}) {
    width: calc(100% - 40rem);
    margin-top: calc(${distances.mFromTop}rem + 35rem);
    padding-bottom: ${distances.mBottomPage / 2}rem;
  }
`

const SubTitle = styled.h2`
  line-height: 1;
  font-weight: 700;
  text-transform: uppercase;
  font-size: ${typographySizes.s}rem;
  color: ${colors.white};
  width: 30%;
  text-align: right;
`
const TopRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  ${SubTitle} {
    display: none;
  }
`

const Title = styled(TitleH1)`
  width: 70%;
  margin-bottom: 80rem;

  @media (max-width: ${mediaQuery.tablet}) {
    width: 100%;
    margin-bottom: 50rem;
    font-size: ${typographySizes.m}rem;
  }
`

const FirstTextRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  ${SubTitle} {
    display: none;
  }
  @media (max-width: ${mediaQuery.tablet}) {
    flex-wrap: wrap;
    ${SubTitle} {
      display: inline-block;
    }
  }
`

const StyledBarText = styled(BarText)`
  width: calc(50% - 60rem);
  align-self: flex-start;
  @media (max-width: ${mediaQuery.tablet}) {
    width: 100%;
    margin-top: 50rem;
    margin-bottom: 20rem;
    margin-left: 0;
  }
`

const FirstImg = styled(Img)`
  width: calc(100% - 40rem);
  margin-left: 20rem;
  margin-top: 50rem;
  transform: rotate(-3deg);
  @media (max-width: ${mediaQuery.tablet}) {
    margin-top: 50rem;
  }
`

const OfferWrapper = styled.div`
  display: flex;
  width: 100%;
  margin-top: 90rem;
  flex-wrap: wrap;
`

const OfferTitle = styled(TitleH2)`
  color: ${colors.mainColor};

  width: calc(33.33% - 50rem);
  z-index: 2;
  margin-bottom: 50rem;
  @media (max-width: ${mediaQuery.tablet}) {
    width: calc(100% - 40rem);
    font-size: ${typographySizes.m}rem;
  }
`

const OfferCol = styled.div`
  width: calc(50% - 20rem);

  margin-bottom: 80rem;
  :nth-of-type(2) {
    margin-top: 200rem;
  }
  :last-of-type {
    margin-bottom: 0;
  }
  @media (max-width: ${mediaQuery.desktop}) {
    width: 100%;
    :nth-of-type(2) {
      margin-top: 0rem;
    }
  }
`

const OfferInfoCol = styled.div`
  width: 75%;
  margin-bottom: 50rem;
  :last-of-type {
    margin-bottom: 0rem;
  }
  @media (max-width: ${mediaQuery.desktop}) {
    width: 100%;
  }
`

const DistrictLabel = styled.h4`
  display: inline-block;
  transform: rotate(-3deg);
  padding: 3rem 5rem;
  background-color: ${colors.white};
  color: ${colors.black};
  font-size: ${typographySizes.s}rem;
  font-weight: 700;
  max-width: 60%;
  line-height: 1.2;
  transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);
`

const StudioTitle = styled(Link)`
  font-size: 35rem;
  color: ${colors.white};
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 30rem;
  display: block;
  cursor: pointer;
`

const OfferRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 30rem;
  :last-of-type {
    margin-bottom: 0;
  }
`

const OfferPosText = styled.h5`
  color: ${colors.white};
  font-size: ${typographySizes.s}rem;
  font-weight: 400;
  text-transform: uppercase;

  text-align: ${props => (props.price ? 'right' : 'left')};

  margin-left: ${props => (props.price ? '20rem' : '0')};
`

const OfferPosSubText = styled.span`
  color: ${colors.mainColor};
  font-size: 9rem;
  font-weight: 400;
  text-transform: uppercase;
`

const OfferPosLef = styled.div``

const OfferPos = ({ name, price, subTitle }) => (
  <OfferRow>
    <OfferPosLef>
      <OfferPosText>{name}</OfferPosText>
      <OfferPosSubText>{subTitle}</OfferPosSubText>
    </OfferPosLef>

    <OfferPosText price>{price}</OfferPosText>
  </OfferRow>
)

const wordsList = [
  {
    words: 'pomoc w promocja i stworzenie klipu ',
  },
  {
    words: ' praca nad tekstem ',
  },
  {
    words: 'stworzenie bitu stworzenie bitu ',
  },
  {
    words: 'realizacje nagrań ',
  },

  {
    words: 'więcej niż studio nagrań ',
  },
  {
    words: 'mastering ',
  },
]
const Oferta = ({ data }) => {
  return (
    <>
      <Seo
        title={data.strapiOffer.SEO ? data.strapiOffer.SEO.Title : null}
        description={
          data.strapiOffer.SEO ? data.strapiOffer.SEO.Description : null
        }
        image={
          data.strapiOffer.SEO
            ? data.strapiOffer.SEO.Img.localFile.publicURL
            : null
        }
      />
      <StyledMain>
        <MainWrapper>
          <Lines leftMargin rightCenter rightMargin />
          <MainCol>
            <TopRow>
              <Title>
                {' '}
                <TextAnimationOnStart>
                  {data.strapiOffer.Title}
                </TextAnimationOnStart>{' '}
              </Title>
            </TopRow>

            <FirstTextRow>
              <img src={arrow} />
              <SubTitle>Oferta</SubTitle>
              <StyledBarText>{data.strapiOffer.First_paragraph}</StyledBarText>
            </FirstTextRow>
            <TextBarAnimation even={false} mobile>
              pomoc w promocja i stworzenie klipu{' '}
            </TextBarAnimation>
            <TextBarAnimation even={true} mobile>
              więcej niż studio nagrań{' '}
            </TextBarAnimation>
            <InView threshold={0} triggerOnce>
              {({ inView, ref, entry }) => (
                <ImageWrapper ref={ref} className={inView && 'show'}>
                  <FirstImg
                    fluid={
                      data.strapiOffer.First_img.localFile.childImageSharp.fluid
                    }
                  />
                </ImageWrapper>
              )}
            </InView>
            <OfferWrapper>
              {/* <OfferCol>
                <OfferTitle>{data.strapiOffer.Studio_offer_title}</OfferTitle>
                {data.strapiOffer.Studio_offer.map(pos => (
                  <OfferInfoCol>
                    <DistrictLabel>{pos.Distric_title}</DistrictLabel>
                    <StudioTitle to="/onas#Localizations">
                      {pos.Studio_title}
                    </StudioTitle>
                    {pos.Offer_position.map(offerPos => (
                      <OfferPos
                        name={offerPos.Name}
                        price={offerPos.Price}
                        subTitle={offerPos.SubTitle}
                      />
                    ))}
                  </OfferInfoCol>
                ))}
              </OfferCol>
              <OfferCol>
                <OfferTitle>{data.strapiOffer.Video_offer_title}</OfferTitle>
                <OfferInfoCol>
                  {data.strapiOffer.Video_offer_position.map(offerPos => (
                    <OfferPos
                      name={offerPos.Name}
                      price={offerPos.Price}
                      subTitle={offerPos.SubTitle}
                    />
                  ))}
                </OfferInfoCol>
              </OfferCol> */}
              <OfferCol>
                <OfferTitle>Nagrania w studio</OfferTitle>
                <UnderlineLink to="/rezerwacja">
                  wyceń i zarezerwuj swoją sesję w studio
                </UnderlineLink>
              </OfferCol>
              <OfferCol>
                <OfferTitle>Mix/ mastering utworów:</OfferTitle>
                <UnderlineLink to="/wycen-swoj-mix">
                  wyceń swój mix
                </UnderlineLink>
              </OfferCol>
              <OfferCol>
                <OfferTitle>Teksty i produkcja utworów:</OfferTitle>
                <UnderlineLink to="/kontakt">
                  skontaktuj się z nami
                </UnderlineLink>
              </OfferCol>
            </OfferWrapper>
          </MainCol>
          <SideCol>
            {[...Array(5)].map((x, i) => (
              <>
                {wordsList.map((words, index) => (
                  <TextBarAnimation even={index % 2 == 0 ? true : false}>
                    {words.words}
                  </TextBarAnimation>
                ))}
              </>
            ))}
          </SideCol>
        </MainWrapper>
      </StyledMain>
    </>
  )
}

export const ArtistsQuery = graphql`
  query OfferQuery {
    strapiOffer {
      SEO {
        Description
        Img {
          localFile {
            publicURL
          }
        }
        Title
      }
      First_paragraph
      Title
      First_img {
        localFile {
          childImageSharp {
            fluid(
              quality: 100
              grayscale: true
              traceSVG: { background: "#000000", color: "#FF3D00" }
            ) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
      }
      Studio_offer_title
      Studio_offer {
        Distric_title
        Studio_title
        Offer_position {
          Name
          Price
          SubTitle
        }
      }
      Video_offer_title
      Video_offer_position {
        Name
        Price
        SubTitle
      }
    }
  }
`

export default Oferta
