import styled from 'styled-components'
import distances from 'src/assets/styles/distances.js'
import colors from 'src/assets/styles/colors'
import typographySizes from 'src/assets/styles/typographySizes.js'

const SmallTitle = styled.h2`
  position: relative;
  color: ${colors.white};
  font-size: ${typographySizes.s}rem;
  font-weight: 400;
  padding-left: ${distances.barW * 2}rem;
  margin-left: 20rem;

  ::before {
    content: '';
    width: ${distances.barW}rem;
    height: calc(100% + ${distances.barW}rem);
    background-color: ${colors.white};
    position: absolute;
    left: 0;
    top: -${distances.barW / 2}rem;
  }
  span {
    font-size: ${typographySizes.s}rem;
    color: ${colors.white};
    font-weight: 700;
  }
`

export default SmallTitle
