import React, { useRef, useEffect } from 'react'

import styled from 'styled-components'
import gsap from 'gsap'

import colors from 'src/assets/styles/colors.js'
import typographySizes from 'src/assets/styles/typographySizes.js'
import mediaQuery from 'src/assets/styles/mediaQuery'

const RotateWrapper = styled.div`
  width: calc(100%);
  margin-top: 80rem;

  transform: rotate(-3deg);
  display: ${props => (props.mobile ? 'none' : 'block')};
  :nth-of-type(odd) {
    transform: rotate(3deg);
  }
  @media (max-width: ${mediaQuery.tablet}) {
    margin-top: 40rem;
    display: ${props => (props.mobile ? 'block' : 'block')};
  }
`

const ModuleWrapper = styled.span`
  width: calc(100%);
  line-height: 1;
  font-weight: 700;
  text-transform: uppercase;
  display: flex;
  align-items: center;

  margin-top: 0rem;
`

const Module = styled.span`
  display: flex;
  align-items: center;

  span {
    color: ${colors.black};
    font-size: ${typographySizes.l}rem;
    background-color: ${colors.secColor};
    font-weight: 700;
    position: relative;
    top: 5rem;
    white-space: nowrap;

    padding-top: 5rem;
    padding-right: 15rem;
  }
`

const TextBarAnimation = ({ children, rotate, even, mobile }) => {
  const wrapper = useRef(null)

  useEffect(() => {
    const movmentTimeline = gsap.timeline({
      repeat: -1,
    })
    const element = wrapper.current

    const bounds = element.children[0].getBoundingClientRect()
    const w = bounds.width

    even
      ? movmentTimeline
          .set(element, { x: -w })
          .to(element, { x: 0, duration: w * 0.01, ease: 'linear' })
      : movmentTimeline
          .set(element, { x: 0 })
          .to(element, { x: -w, duration: w * 0.01, ease: 'linear' })
  })

  return (
    <RotateWrapper rot={rotate} mobile={mobile}>
      <ModuleWrapper ref={wrapper}>
        {[...Array(10)].map((x, i) => (
          <Module key={i}>
            <span>{children} </span>
          </Module>
        ))}
      </ModuleWrapper>
    </RotateWrapper>
  )
}

export default TextBarAnimation
