import React from 'react'
import styled from 'styled-components'
import { InView } from 'react-intersection-observer'

const Word = styled.span`
  font-family: inherit !important;
  font-size: inherit !important;
  color: inherit !important;
  transform: translateY(0rem);
  display: inline-block;
  word-wrap: break-word;
  white-space: normal;
  span {
    font-family: inherit !important;
    font-size: inherit !important;
    color: inherit !important;
    opacity: 0;
    transform: translateY(20rem);
    display: block;
    word-wrap: break-word;
    white-space: normal;
  }
  &.show span {
    opacity: 1;
    transform: translateY(0rem);
    transition: all 0.5s ${props => props.delay}s ease;
  }
`

const TextAnimationOnStart = ({ children }) => {
  const myArray = children.split(' ')

  return (
    <>
      <InView threshold={0} triggerOnce>
        {({ inView, ref, entry }) =>
          myArray.map((word, index) => (
            <Word ref={ref} delay={index * 0.02} className={inView && 'show'}>
              <span>{word}&nbsp;</span>
            </Word>
          ))
        }
      </InView>
    </>
  )
}

export default TextAnimationOnStart
